.post {
    header {
        margin-bottom: 30px;
    }

    .post-meta {
        margin-bottom: 10px;
    }

    .title {
        font-size: 1.8em;
    }

    .post-category:hover {
        border: unset;
    }

    .post-body {
        display: block;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;
        
        h1, h2, h3, h4 {
            margin-top: 20px;
            margin-bottom: 10px;
        }
        h1 {
            font-size: 1.7em;
            font-weight: 600;
        }
        h2 {
            font-size: 1.5em;
            font-weight: 600;
        }
        h3 {
            font-size: 1.5em;
        }
        h4 {
            font-size:  1.3em;
        }

        a {
            display: inline-block;
            max-width: 100%;
            overflow: auto;
            line-height: normal;
            vertical-align: middle;
            color: $b2Color;
            padding: 2px 2px 3px;
            border: 1px solid $b2Color;
        }

        b, strong {
            color: $bColor;
        }

        ul {
            margin-left: none;
            padding-left: 1em;
            list-style: none;

            li:before {
                display: inline-block;
                content: "-";
                width: 1em;
                margin-left: -1em;
            }

            li {
                margin-top: 5px;
                margin-bottom: 5px;
            }
        }

        pre {
            display: inline-block;
            background-color: $bgColor;
            border: 1px solid $codeColor;
            padding: 5px;
            margin-top: 10px;
            margin-bottom: 10px;
            width: 100%;
            color: $codeColor;
        }

        pre::-webkit-scrollbar {
            width: 3px;
        }
         
        pre::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px $scrollBarColor;
        }
         
        pre::-webkit-scrollbar-thumb {
          background-color: $scrollBarColor;
          outline: 1px solid $scrollBarColor;
        }

        img {
            display: block;
            margin: 10px auto;
            border: 1px solid $bColor;
        }
    }
}