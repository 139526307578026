.footer {
    background-color: $bgColor;
    margin-top: 100px;
    border-top: 1px solid #666;
}

.svg-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    fill: $fgColor;
    vertical-align: text-top;
}

.social-media-list {
    li + li {
      padding-top: 5px;
    }
}