@import "./vars";

.navbar {
    background-color: $bgColor;
    color: $fgColor;
    border-bottom: 1px solid $bColor;
    margin-bottom: 20px;

    .navbar-item {
        color: $fgColor;
    }

    .navbar-item:hover {
        color: $fgColor;
        background-color: unset;
        border-bottom: 2px solid $bColor;
    }

    .navbar-menu {
        background-color: unset;
    }
}

.navbar-brand > .navbar-item{
    color: $bColor;
    font-weight: bold;
}