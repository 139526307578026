/* Spacing */
.gist
{
    margin-top: 10px;
    margin-bottom: 10px;
}

/* Body */
.gist-data tbody
{
    background-color: $bgColor;
}

/* Line Numbers */
.gist-data tbody td:nth-of-type(1)
{
    color: $fgColor !important;
}

/* Code */
.gist-data tbody td:nth-of-type(2)
{
    color: $codeColor !important;
}

/* Comments */
.pl-c
{
    color: #666 !important;
}

/* Function */
.pl-k, tbody tr:first-child .blob-code, tbody tr:last-child .blob-code
{
    color: $bColor !important;
}

/* Function Name */
.pl-en
{
    color: $b2Color !important;
}

/* Function Method */
.pl-c1
{
    color: #FFFFFF !important;
}
/* "'s around Strings */
.pl-pds
{
    color: #fffb00 !important;
}

/* Strings */
.pl-s
{
    color: #fffb00 !important;
}

.pl-s1
{
    color: $codeColor !important;
}

.pl-smi
{
    color: #0080ff !important;
}

/* Element paramenter */
.pl-e {
    color: #c9abff !important;
}

.pl-ent
{
    color: $b2Color !important;
}

/* Meta */
.gist-meta {
    background-color: $bgColor !important;
    color: $fgColor !important;

    a {
        color: $bColor !important;
    }
}

/* Border */
.gist-file {
    border: 1px solid $codeColor !important;
}

/* Scroll Bar */
.gist .gist-data {
    border: 0px !important;

    ::-webkit-scrollbar {
        width: 3px;
        background-color: $bgColor;
    }
     
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px $scrollBarColor;
    }
     
    ::-webkit-scrollbar-thumb {
      background-color: $scrollBarColor;
      outline: 1px solid $scrollBarColor;
    }

}