.post-list {
    .post {
        margin-bottom: 30px;
        
        .post-title {
            font-size: 1.5em;
        }

        .post-category:hover {
            border: unset;
        }

        .post-excerpt {
            margin-top: 10px;

            a {
                color: $b2Color;
                padding: 2px;
                border: 1px solid $b2Color;
            }
    
            b, strong {
                color: $bColor;
            }

            pre {
                background-color: $bgColor;
                color: $codeColor;
            }
        }
    }
}