@import "custom/vars";

body {
    min-height: 100vh;
    background-color: $bgColor;
    font-family: 'Inconsolata', monospace;
    color: $fgColor;
}

a {
    color: $bColor;
}
a:hover {
    color: $bColor;
    border-bottom: 1px solid $bColor;
}

code {
    background-color: unset;
}

.container {
    padding: 5px;
}

.title {
    color: $fgColor;
}

.tag {
    background-color: transparent !important;
    color: $fgColor !important;
    border: 1px solid $fgColor !important;
}

@import "custom/fonts";
@import "custom/navbar";
@import "custom/postlist";
@import "custom/post";
@import "custom/footer";
@import "custom/forms";
@import "custom/gist";