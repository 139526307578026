input {
    background-color: $bgColor;
    border: 1px solid $b2Color;
    color: $fgColor;
}

button {
    background-color: $bgColor;
    color: $fgColor;
    border: 1px solid $b2Color;
}